import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Auth } from "./firebase/firebase";
import { GetUserData } from "./firebase/functions/profile";
import { setUserData } from "./redux/slices/UserSlice";
import { Logout } from "./firebase/functions/auth";
import { Spinner } from "reactstrap";

const App = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(Auth, (user) => {
      setFetchingUserData(true);
      if (user) {
        GetUserData(user.uid)
          .then((res) => {
            if (res.userData.status?.toLowerCase() === "active") {
              setFetchingUserData(false);
              setIsLoggedIn(true);
              setIsEmailVerified(user.emailVerified);
              dispatch(
                setUserData({
                  user: res.userData,
                })
              );
              // Set New user Cookie
              document.cookie = `user=${JSON.stringify({
                ...res.userData,
                photoURL: encodeURIComponent(res.userData.photoURL),
              })}; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/; max-age=604800;`;
            } else {
              toast.warn("Your account is not active. Please contact support");
              setIsLoggedIn(false);
              setFetchingUserData(false);
              setIsEmailVerified(true);
              dispatch(
                setUserData({
                  user: null,
                })
              );

              setTimeout(() => {
                Logout();
                navigator("/auth-login");
              }, 5000);
            }
          })
          .catch((error) => {
            console.log("Error 2", error);
            toast.error(error?.message || "An error occurred. Please try again later");
            handleLogout();
            navigator("/");
          });
      } else {
        handleLogout();
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setFetchingUserData(false);
    setIsEmailVerified(true);
    dispatch(
      setUserData({
        user: null,
      })
    );
    Logout();
    // Remove cookie
    document.cookie = `user=; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/;`;
  };

  return (
    <ThemeProvider>
      {fetchingUserData ? (
        <>
          <section className="d-flex justify-content-center align-items-center vh-100">
            <Spinner size="lg" color="primary" />
          </section>
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <Router authenticated={isLoggedIn} emailVerified={isEmailVerified} />
          ) : (
            <Router authenticated={false} emailVerified={false} />
          )}
        </>
      )}
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
