// Auth Functions for Firebase
import {
  GoogleAuthProvider,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  verifyPasswordResetCode,
} from "firebase/auth";
import { Auth, DB } from "../firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { defaultProfile } from "./profile";

var actionCodeSettings = {
  url: `${process.env.REACT_APP_MAIN_WEBSITE_URL}`,
  handleCodeInApp: false,
};

const RegisterWithEmailAndPassword = async (fullName, email, password) => {
  try {
    // Check if email is already in use
    const q = query(collection(DB, "users"), where("email", "==", email));
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
      return {
        result: "error",
        message: "Email is already in use",
        data: null,
      };
    }

    const user = await createUserWithEmailAndPassword(Auth, email, password);

    const auth = getAuth();

    await updateProfile(auth.currentUser, { displayName: fullName });

    // Store user in database
    await addDoc(collection(DB, "users"), {
      ...defaultProfile,
      uid: user.user.uid,
      photoURL: null,
      email: email,
      fullName: fullName,
      name: fullName,
      displayName: fullName,
      dob: new Date().toISOString(),
      role: "user",
      emailVerified: false,
      status: "active",
    });

    // Send email verification
    await sendEmailVerification(auth.currentUser, actionCodeSettings);

    return {
      result: "success",
      message: "User registered successfully",
      data: user.user,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

const LoginWithEmailAndPassword = async (email, password) => {
  try {
    const user = await signInWithEmailAndPassword(Auth, email, password);
    return {
      result: "success",
      message: "User logged in successfully",
      data: user.user,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

const LoginWithGoogle = async () => {
  try {
    const res = await signInWithPopup(Auth, new GoogleAuthProvider());

    // Check if user exists in database
    const q = query(collection(DB, "users"), where("email", "==", res.user.email));

    const docs = await getDocs(q);

    if (docs.docs.length === 0) {
      // Store user in database
      await addDoc(collection(DB, "users"), {
        ...defaultProfile,
        uid: res.user.uid,
        email: res.user.email,
        fullName: res.user.displayName,
        name: res.user.displayName,
        displayName: res.user.displayName,
        dob: new Date().toISOString(),
        role: "user",
        emailVerified: true,
        photoURL: res.user.photoURL,
      });
    }
    return {
      result: "success",
      message: "User logged in successfully",
      data: {
        ...defaultProfile,
        uid: res.user.uid,
        email: res.user.email,
        fullName: res.user.displayName,
        name: res.user.displayName,
        displayName: res.user.displayName,
        dob: new Date().toISOString(),
        role: "user",
        emailVerified: true,
        photoURL: res.user.photoURL,
      },
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

const ResendVerificationEmail = async () => {
  try {
    await sendEmailVerification(Auth.currentUser, actionCodeSettings);
    return {
      result: "success",
      message: "Verification email sent",
      data: null,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

const ResetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(Auth, email);
    return {
      result: "success",
      message: "Reset link sent to your email address",
      data: null,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

const Logout = async () => {
  try {
    await signOut(Auth);
    return true;
  } catch (error) {
    return error;
  }
};

const VerifyEmailAction = async (actionCode, continueUrl) => {
  try {
    const res = await applyActionCode(Auth, actionCode);

    return {
      result: "success",
      message: "Email verified successfully",
      data: res,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

const ResetPasswordAction = async (actionCode, newPassword) => {
  try {
    const email = await verifyPasswordResetCode(Auth, actionCode);

    await confirmPasswordReset(Auth, actionCode, newPassword);

    return {
      result: "success",
      message: "Password reset successfully",
      data: email,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

export {
  RegisterWithEmailAndPassword,
  LoginWithEmailAndPassword,
  LoginWithGoogle,
  ResendVerificationEmail,
  ResetPassword,
  Logout,
  VerifyEmailAction,
  ResetPasswordAction,
};
