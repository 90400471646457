import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { Auth, DB, Storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const defaultProfile = {
  name: "",
  displayName: "",
  dob: new Date().toISOString(),
  email: "",
  phone: "",
  address: "",
  address2: "",
  state: "",
  country: "",
};

// Get Profile Data
const GetUserData = async (userUuid) => {
  try {
    const q = query(collection(DB, "users"), where("uid", "==", userUuid));
    const docs = await getDocs(q);
    const userData = docs.docs[0].data();

    return {
      result: "success",
      message: "User data fetched Successfully",
      userData: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message,
      userData: null,
    };
  }
};

// Update user profile
const UpdateProfile = async (userUuid, data) => {
  try {
    const q = query(collection(DB, "users"), where("uid", "==", userUuid));
    const docs = await getDocs(q);
    const userRef = docs.docs[0].ref;

    await updateDoc(userRef, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message,
      data: null,
    };
  }
};

// Update User Profile Image
const UpdateProfileImage = async (userUuid, image) => {
  try {
    // Update image to storage
    const storageRef = ref(Storage, `users/${userUuid}/profile`);

    // Upload the file and metadata
    await uploadBytesResumable(storageRef, image);

    // Get the download URL
    const url = await getDownloadURL(storageRef);

    // Update user profile
    const data = { photoURL: url };
    const q = query(collection(DB, "users"), where("uid", "==", userUuid));
    const docs = await getDocs(q);
    const userRef = docs.docs[0].ref;
    await updateDoc(userRef, data);

    Auth.updateCurrentUser({
      ...Auth.currentUser,
      photoURL: url,
    });

    return {
      result: "success",
      message: "User profile image updated successfully",
      data: url,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

export { defaultProfile, GetUserData, UpdateProfile, UpdateProfileImage };
