// Get all user transactions

import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { DB } from "../firebase";

const GetTransactionsOfUser = async (userId) => {
  if (!userId) {
    throw new Error("User ID is required");
  }

  const q = query(collection(DB, "transactions"), where("userId", "==", userId));
  const docs = await getDocs(q);
  const transactionsData = docs.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  // Filter out transactions that are marked as deleted
  const filteredTransactions = transactionsData.filter((transaction) => {
    return transaction.status !== "deleted";
  });

  //sort transactions by date. latest first
  const sortedTransactions = filteredTransactions.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return {
    result: "success",
    message: "Transactions fetched successfully",
    transactions: sortedTransactions,
  };
};

const DeleteTransaction = async (transactionId) => {
  if (!transactionId) {
    throw new Error("Transaction ID is required");
  }

  // Get a reference to the transaction
  const q = query(collection(DB, "transactions"), where("paymentId", "==", transactionId));
  const docs = await getDocs(q);
  const transaction = docs.docs[0];

  if (!transaction) {
    throw new Error("Transaction not found");
  }

  // Delete the transaction
  // Update the transaction status to canceled
  await updateDoc(transaction.ref, {
    status: "deleted",
  });

  return {
    result: "success",
    message: "Transaction deleted successfully",
  };
};

const CancelTransaction = async (transactionId) => {
  if (!transactionId) {
    throw new Error("Transaction ID is required");
  }

  // Get a reference to the transaction
  const q = query(collection(DB, "transactions"), where("paymentId", "==", transactionId));
  const docs = await getDocs(q);
  const transaction = docs.docs[0];

  if (!transaction) {
    throw new Error("Transaction not found");
  }

  // Update the transaction status to canceled
  await updateDoc(transaction.ref, {
    status: "canceled",
  });

  return {
    result: "success",
    message: "Transaction canceled successfully",
  };
};

// Get user's current month transaction
const GetTransactionsOfUserCurrentMonth = async (userId) => {
  if (!userId) {
    throw new Error("User ID is required");
  }

  const q = query(collection(DB, "transactions"), where("userId", "==", userId));
  const docs = await getDocs(q);
  const transactionsData = docs.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  // Get all transactions from current month date till previous month date
  const filteredTransactions = transactionsData.filter((transaction) => {
    const transactionDate = new Date(transaction.date);
    // get transactions from last 30 days
    return (
      transactionDate >= new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000) && transaction.status === "paid"
    );
  });

  if (filteredTransactions.length === 0) {
    return {
      result: "success",
      message: "No transactions found for current month",
      transaction: null,
    };
  }

  // Get Transaction that has highest amount
  const highestAmountTransaction = filteredTransactions.reduce((prev, current) => {
    return prev.amount > current.amount ? prev : current;
  });

  return {
    result: "success",
    message: "Transaction fetched successfully",
    transaction: highestAmountTransaction,
  };
};

const GetAllPricePlans = async () => {
  try {
    const res = await getDocs(collection(DB, "price-plans"));
    const data = res.docs.map((doc) => doc.data());
    return {
      status: "success",
      message: "Price plans fetched successfully",
      data: data,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not fetch price plans",
      data: null,
    };
  }
};

export { GetTransactionsOfUser, DeleteTransaction, CancelTransaction, GetTransactionsOfUserCurrentMonth, GetAllPricePlans };
