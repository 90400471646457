import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon } from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import AuthFooter from "./AuthFooter";
import { ResetPasswordAction, VerifyEmailAction } from "../../firebase/functions/auth";
import { toast } from "react-toastify";

function VerifyEmail() {
  const navigate = useNavigate();
  const [verifingEmail, setVerifingEmail] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resettingPassword, setResettingPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [actionMode, setActionMode] = useState("");
  const [actionCode, setActionCode] = useState("");

  const verifyUserEmail = async (actionCode, continueUrl = "") => {
    try {
      setVerifingEmail(true);
      const res = await VerifyEmailAction(actionCode, continueUrl);

      if (res?.result === "success") {
        setEmailVerified(true);
        setTimeout(() => {
          navigate("/subscription");
          window.location.reload();
        }, 2000);
      } else {
        setEmailVerified(false);
      }
      setVerifingEmail(false);
    } catch (error) {
      toast.error(error.message);
      setEmailVerified(false);
      setVerifingEmail(false);
    }
  };

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else if (!actionCode) {
      toast.error("Invalid action code");
      return;
    } else {
      try {
        setResettingPassword(true);
        const res = await ResetPasswordAction(actionCode, password);
        if (res.result === "success") {
          toast.success(res.message);
          navigate("/auth-login");
        } else {
          toast.error(res.message);
        }
        setResettingPassword(false);
      } catch (error) {
        toast.error(error.message);
        setResettingPassword(false);
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const mode = urlParams.get("mode");
    const actionCode = urlParams.get("oobCode");
    const continueUrl = urlParams.get("continueUrl");

    if (mode === "verifyEmail") {
      setActionMode("verifyEmail");
      verifyUserEmail(actionCode, continueUrl);
    } else if (mode === "resetPassword") {
      setActionMode("resetPassword");
      setActionCode(actionCode);
    } else {
      navigate("/auth-login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head title="Success" />
      <Block className="nk-block-middle nk-auth-body">
        <div className="brand-logo pb-5">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <BlockHead>
          {actionMode === "verifyEmail" && (
            <BlockContent>
              <BlockTitle tag="h4">
                {verifingEmail ? "Verifying Email" : emailVerified ? "Email Verified" : "Email Verification Failed"}
              </BlockTitle>
              {verifingEmail ? (
                <section className="d-flex justify-content-start align-items-start my-5">
                  <Spinner size="lg" color="primary" />
                </section>
              ) : (
                <>
                  {emailVerified ? (
                    <BlockDes className="text-success">
                      <p>
                        Your email has been verified successfully. You can now login to your account using your email
                        and password.
                      </p>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => {
                          navigate("/");
                          window.location.reload();
                        }}
                      >
                        Back to Login
                      </Button>
                    </BlockDes>
                  ) : (
                    <BlockDes className="text-danger">
                      <p>Verification failed. Please try again later.</p>
                      <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                        <Button color="primary" size="lg">
                          Resend Verification Email
                        </Button>
                      </Link>
                    </BlockDes>
                  )}
                </>
              )}
            </BlockContent>
          )}

          {actionMode === "resetPassword" && (
            <BlockContent>
              <BlockTitle tag="h4">Reset Password</BlockTitle>
              <BlockDes>
                <p>Enter your new password below to reset your password.</p>
              </BlockDes>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="form-control-wrap d-flex align-items-center position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-lg"
                      id="password"
                      placeholder="Enter your new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn position-absolute end-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon name={showPassword ? "eye-off" : "eye"} />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <div className="form-control-wrap d-flex align-items-center position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control form-control-lg"
                      id="confirmPassword"
                      placeholder="Confirm your new password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn position-absolute end-0"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      <Icon name={showConfirmPassword ? "eye-off" : "eye"} />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <Button type="submit" color="primary" size="lg" disabled={resettingPassword}>
                    {resettingPassword ? "Resetting Password" : "Reset Password"}
                  </Button>
                </div>
              </form>
            </BlockContent>
          )}
        </BlockHead>
      </Block>
      <AuthFooter />
    </>
  );
}

export default VerifyEmail;
