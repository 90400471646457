import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import SubsDashboard from "../pages/panel/subscription/Index";
import SubsSubscriptionDetails from "../pages/panel/subscription/SubscriptionDetails";
import SubsSupport from "../pages/panel/subscription/Support";
import SubsProfileLayout from "../pages/panel/subscription/ProfileLayout";
import SubsProfile from "../pages/panel/subscription/Profile";
import SubsPricing from "../pages/panel/subscription/Pricing";
import SubsPayments from "../pages/panel/subscription/Payments";
import SubsInvoices from "../pages/panel/subscription/Invoices";
import SubsInvoiceDetails from "../pages/panel/subscription/InvoiceDetails";
import SubsInvoicePrint from "../pages/panel/subscription/InvoicePrint";
import SubsFaqs from "../pages/panel/subscription/Faqs";
import SubsDownloads from "../pages/panel/subscription/Downloads";
import SubsContact from "../pages/panel/subscription/Contact";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import LayoutSubscription from "../layout/Index-subscription";
import { Logout } from "../firebase/functions/auth";
import { Spinner } from "reactstrap";
import EmailVerification from "../pages/auth/EmailVerification";
import VerifyEmail from "../pages/auth/VerifyEmail";

const Pages = ({ authenticated = false, emailVerified = false }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {authenticated && emailVerified ? (
        <>
          <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutSubscription />}>
            <Route path="/">
              <Route index element={<SubsDashboard />}></Route>
              <Route path="index" element={<SubsDashboard />}></Route>
              <Route path="subscriptions" element={<SubsSubscriptionDetails />}></Route>
              <Route path="pricing" element={<SubsPricing />}></Route>
              {/* <Route path="subscriptions" element={<SubsSubscriptions />}></Route> */}
              {/* <Route path="team" element={<SubsTeam />}></Route> */}
              <Route element={<SubsProfileLayout />}>
                <Route path="profile" element={<SubsProfile />}></Route>
              </Route>
              <Route path="support" element={<SubsSupport />}></Route>
              <Route path="payments" element={<SubsPayments />}></Route>
              <Route path="invoices" element={<SubsInvoices />}></Route>
              <Route path="invoice-details/:invoiceId" element={<SubsInvoiceDetails />}></Route>
              <Route path="payments/:invoiceId" element={<SubsInvoiceDetails />}></Route>
              <Route path="faqs" element={<SubsFaqs />}></Route>
              <Route path="downloads" element={<SubsDownloads />}></Route>
              <Route path="contact" element={<SubsContact />}></Route>
              <Route path="logout" element={<LogoutComponent />}></Route>
            </Route>
          </Route>

          <Route element={<LayoutNoSidebar />}>
            <Route path="/auth-action" element={<VerifyEmail />}></Route>
          </Route>

          <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
            <Route path="invoice-print/:invoiceId" element={<SubsInvoicePrint />}></Route>
          </Route>
          <Route path="*" element={<Navigate to={"/"} />}></Route>
        </>
      ) : authenticated && !emailVerified ? (
        <Route path="/" element={<LayoutNoSidebar />}>
          <Route index path="/" element={<EmailVerification />}></Route>
          <Route path="auth-action" element={<VerifyEmail />}></Route>
          <Route path="*" element={<EmailVerification />}></Route>
        </Route>
      ) : (
        <Route path="" element={<LayoutNoSidebar />}>
          <Route index element={<Login />}></Route>
          <Route path="auth-reset" element={<ForgotPassword />}></Route>
          <Route path="auth-register" element={<Register />}></Route>
          <Route path="auth-login" element={<Login />}></Route>
          <Route path="email-verification" element={<Success />}></Route>
          <Route path="auth-action" element={<VerifyEmail />}></Route>
          <Route path="*" element={<Navigate to={"/"} />}></Route>
        </Route>
      )}
    </Routes>
  );
};

const LogoutComponent = () => {
  useEffect(() => {
    Logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="d-flex justify-content-center align-items-center vh-100">
      <Spinner size="lg" color="primary" />
    </section>
  );
};

export default Pages;
