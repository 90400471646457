import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Col,
  Row,
  RSelect,
} from "../../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Card, Modal, Spinner } from "reactstrap";
import { svgData } from "../../components/crafted-icons/NioIconData";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { AES } from "crypto-js";
import { GetAllPricePlans, GetTransactionsOfUserCurrentMonth } from "../../../firebase/functions/transactions";

const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0];

const Pricing = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [pricingTableData, setPricingTableData] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState("");
  const [requestingPaymentLink, setRequestingPaymentLink] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [planType, setPlanType] = useState("monthly");
  const [currency, setCurrency] = useState("USD");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const selectPlan = async ({ amount, description, callbackUrl, planData }) => {
    setRequestingPaymentLink(true);

    // Encrypt the  user uid
    const encryptedUid = AES.encrypt(user.uid, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/payment-request`,
        {
          amount: amount,
          description: description,
          callbackUrl: callbackUrl,
          user: {
            uid: user.uid,
            name: user.fullName,
            email: user.email,
            phone: user.phone,
          },
          planData: {
            ...planData,
            planType: planType,
            currency: currency,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${encryptedUid}`,
          },
        }
      )
      .then((response) => {
        setRequestingPaymentLink(false);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        setRequestingPaymentLink(false);
        setSelectedPlan("");
        toast.error(error?.message ?? "Something went wrong. Please try again later.");
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params.get("Status");
    const amount = params.get("amount");
    const payment_id = params.get("payment_id");
    const authority = params.get("Authority");
    const description = params.get("description");

    if (amount && payment_id && authority && description) {
      setSelectedPlan(description);
      setRequestingPaymentLink(true);

      if (status === "OK") {
        // Add toast with promise
        toast
          .promise(
            axios.post(
              `${process.env.REACT_APP_API_URL}/verify-payment`,
              {
                amount: amount,
                payment_id: payment_id,
                authority: authority,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            ),
            {
              pending: "Verifying payment...",
              success: {
                render() {
                  return "Payment successful. Your plan has been updated.";
                },
              },
              error: {
                render({ data }) {
                  console.log(data);
                  return `${data?.response?.data?.message ?? "Payment failed. Please try again later."}`;
                },
              },
            }
          )
          .finally(() => {
            setSelectedPlan("");
            setRequestingPaymentLink(false);
            navigate(`/pricing`);
            getCurrentPlan();
          });
      } else if (status === "NOK") {
        toast.error("Payment failed. Please try again later.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentPlan = async () => {
    try {
      const res = await GetTransactionsOfUserCurrentMonth(user.uid);

      if (res.result === "success" && res.transaction) {
        setCurrentPlan(res.transaction.description);
      } else {
        setCurrentPlan("");
      }
    } catch (error) {
      toast.error("Failed to get current plan. Please try again later.");
    }
  };

  const getPricingTables = async () => {
    try {
      setFetchingPlans(true);
      const res = await GetAllPricePlans();
      if (res.status === "success") {
        setPricingTableData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message ?? "Failed to fetch price plans. Please try again later.");
    } finally {
      setFetchingPlans(false);
    }
  };

  useEffect(() => {
    getPricingTables();
    getCurrentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head title="Pricing"></Head>
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span>Pricing</span>
          </div>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Choose Suitable Plan
              </BlockTitle>
              <BlockDes>
                <p>You can change your plan any time by upgrade your plan</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {fetchingPlans ? (
          <>
            <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
              <Spinner size="lg" color="primary" />
            </section>
          </>
        ) : (
          <>
            <Block>
              <Row className="g-gs justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <p className="text-soft mb-0 me-2">Monthly</p>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="planType"
                      defaultChecked={planType === "yearly" ? true : false}
                      onChange={(e) => setPlanType(e.target.checked ? "yearly" : "monthly")}
                    />
                    <label className="custom-control-label text-soft" htmlFor="planType"></label>
                  </div>
                  <p className="text-soft">Yearly</p>
                </div>
                <div className="w-25">
                  <RSelect
                    options={[
                      { value: "USD", label: "USD" },
                      { value: "IRR", label: "IRR" },
                    ]}
                    onChange={(selectedOption) => {
                      setCurrency(selectedOption.value);
                    }}
                    placeholder="Select Currency"
                  />
                </div>
              </Row>
            </Block>

            <Block>
              <Row className="g-gs">
                {pricingTableData.map((item) => {
                  return (
                    <Col md="4" key={item.id}>
                      <Card className={`card-bordered pricing text-center ${item.tags ? "recommend" : ""}`}>
                        {currentPlan && currentPlan.toLowerCase() === item.title.toLowerCase() && (
                          <Badge color="primary" className="pricing-badge">
                            Current Plan
                          </Badge>
                        )}

                        <div className="pricing-body">
                          <div className="pricing-title w-220px mx-auto">
                            <h5 className="title">{item.title}</h5>
                            <span className="sub-text">{item.description}</span>
                          </div>
                          <div className="pricing-amount">
                            <div className="amount">
                              {currency === "IRR"
                                ? planType === "yearly"
                                  ? item.yearlyTomans
                                  : item.monthlyTomans
                                : planType === "yearly"
                                ? item.yearlyUSD
                                : item.monthlyUSD}
                              {currency === "IRR" ? " تومان" : " $"}
                              {planType === "yearly" ? <span>/yr</span> : <span>/mo</span>}
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center p-2 border-top mt-2">
                              {item.features.map((feature, index) => (
                                <p key={index} className="text-soft text-center">
                                  {feature}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="pricing-action">
                            <Button
                              color="primary"
                              disabled={
                                (selectedPlan.toLowerCase() === item.title.toLowerCase() && requestingPaymentLink) ||
                                currentPlan.toLowerCase() === item.title.toLowerCase()
                              }
                              onClick={() => {
                                setSelectedPlan(item.title);
                                setSelectedPlanDetails(item);
                                setShowConfirmModal(true);
                              }}
                            >
                              {currentPlan.toLowerCase() === item.title.toLowerCase() ? (
                                "Current Plan"
                              ) : requestingPaymentLink && selectedPlan.toLowerCase() === item.title.toLowerCase() ? (
                                <Spinner size="sm" color="white" />
                              ) : (
                                "Select Plan"
                              )}
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Block>
            <Block>
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="nk-help">
                    <div className="nk-help-img">{helpSupportIcon.svg}</div>
                    <div className="nk-help-text">
                      <h5>We’re here to help you!</h5>
                      <p className="text-soft">
                        Ask a question or file a support ticket or report an issues. Our team support team will get back
                        to you by email.
                      </p>
                    </div>
                    <div className="nk-help-action">
                      <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-lg btn-outline-primary">
                        Get Support Now
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>
            </Block>
          </>
        )}

        <Modal
          isOpen={showConfirmModal && selectedPlan}
          size="md"
          toggle={() => {
            setShowConfirmModal(!showConfirmModal);
            setSelectedPlan("");
            setSelectedPlanDetails(null);
          }}
        >
          <button
            className="close"
            onClick={(ev) => {
              ev.preventDefault();
              setShowConfirmModal(false);
            }}
          >
            <em className="icon ni ni-cross"></em>
          </button>
          <div className="modal-body modal-body-md">
            <h4 className="nk-modal-title title">Proceed to pay for {selectedPlan ?? ""} plan</h4>
            <p>
              Are you sure you want to proceed to payment for <strong>{selectedPlan}</strong> plan? You will be
              redirected to payment gateway to complete the payment of{" "}
              <b>
                {" "}
                {currency === "IRR" ? "IRR" : "$"}{" "}
                {currency === "IRR"
                  ? planType === "yearly"
                    ? selectedPlanDetails?.yearlyTomans ?? 0
                    : selectedPlanDetails?.monthlyTomans ?? 0
                  : planType === "yearly"
                  ? selectedPlanDetails?.yearlyUSD ?? 0
                  : selectedPlanDetails?.monthlyUSD ?? 0}
              </b>
            </p>

            <div className="form">
              <div className="form-group">
                <label className="form-label d-flex align-items-center gap-1" htmlFor="confirmTerms">
                  <input
                    type="checkbox"
                    name="confirmTerms"
                    id="confirmTerms"
                    style={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer",
                    }}
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                  />
                  <span
                    className="ml-2"
                    style={{
                      userSelect: "none",
                    }}
                  >
                    I agree to the{" "}
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        marginLeft: "2px",
                      }}
                    >
                      terms and conditions.
                    </a>
                  </span>
                </label>
              </div>
              <ul className="align-center flex-wrap g-3 mt-2">
                <li>
                  <button
                    className="btn btn-primary"
                    disabled={!acceptedTerms || requestingPaymentLink || !selectedPlan || !selectedPlanDetails}
                    onClick={(ev) => {
                      ev.preventDefault();
                      selectPlan({
                        amount:
                          planType === "yearly"
                            ? selectedPlanDetails.yearlyTomans.toString()
                            : selectedPlanDetails.monthlyTomans.toString(),
                        description: selectedPlanDetails.title,
                        callbackUrl: `${window.location.origin}/pricing`,
                        planData: selectedPlanDetails,
                      });
                    }}
                  >
                    {requestingPaymentLink ? <Spinner size="sm" color="white" /> : "Proceed to pay"}
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSelectedPlan("");
                      setSelectedPlanDetails(null);
                      setShowConfirmModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default Pricing;
