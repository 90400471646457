export const menu = [
  { heading: "Menu" },
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/index",
  },
  {
    icon: "file-text",
    text: "My Subscription",
    link: "/subscriptions",
  },
  {
    icon: "file-text",
    text: "Pricing",
    link: "/pricing",
  },
  {
    icon: "report-profit",
    text: "Payment History",
    link: "/payments",
  },
  // {
  //   icon: "download-cloud",
  //   text: "Download",
  //   link: "/downloads",
  // },
  {
    icon: "account-setting",
    text: "Account Setting",
    link: "/profile",
  },
];
export const secoundmenu = [
  {
    heading: "Help Center",
  },
  {
    text: "FAQs",
    link: "/faqs",
  },
  {
    text: "Contact",
    link: "/contact",
  },
  {
    text: "Support",
    link: "/support",
  },
];
export default menu;
